import { debounce } from 'lodash';

const createStore = formName => {
  /* this expect value shape to be {.., content} where content is RCE contentState
     use localStorage directly for performance to avoid bridge */
  const key = localSt.createKey(formName);

  const onChange = debounce(formValue => {
    localSt.save(key, expirationService.wrap(formValue));
  }, 800);

  const reset = () => {
    onChange.cancel();
    localSt.save(key, null);
  };
  return {
    onChange,
    onSubmit: reset,
    onReset: reset,
    load: () => {
      const prevState = localSt.get(key);
      if (expirationService.isValid(prevState)) {
        const data = expirationService.unwrap(prevState);
        return data;
      }
    },
  };
};
const cache = {};
export const createLSFormStore = formName => {
  if (!cache[formName]) {
    cache[formName] = createStore(formName);
  }

  return cache[formName];
};

const localSt = {
  createKey: formName => `frm-ff-${formName}`,

  save: (k, v) => {
    try {
      window.localStorage.setItem(k, JSON.stringify(v));
    } catch (error) {
      console.info(error);
    }
  },
  get: k => {
    try {
      const item = window.localStorage.getItem(k);
      if (item) {
        return JSON.parse(item);
      }
      return null;
    } catch (error) {
      return null;
    }
  },
};

const expirationService = {
  TIME_VALID: 1000 * 60 * 60 * 24 * 7, // 7 days
  wrap: data => ({
    data,
    ts: Date.now(),
  }),
  unwrap: wrappedData => wrappedData.data,
  isValid: wrappedData =>
    wrappedData && wrappedData.ts && Date.now() < wrappedData.ts + expirationService.TIME_VALID,
};
